import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ExternalLink from '../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/notification/accessibility",
  "title": "Notification - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Avoid using automatically disappearing toast notifications for critical information or very long messages. It is possible that the notification disappears before the user or screen reader can react to the message. Refer to `}<ExternalLink href="https://www.w3.org/WAI/WCAG21/Understanding/no-timing.html" mdxType="ExternalLink">{`WCAG 2.1 No timing requirement`}</ExternalLink>{` for more information.`}</li>
      <li parentName="ul">{`Remember that colour should never be the only way of conveying information. Make sure the meaning of the notification is clearly described by the title and body. Refer to `}<ExternalLink href="https://www.w3.org/WAI/WCAG21/Understanding/use-of-color.html" mdxType="ExternalLink">{`WCAG 2.1 Use of Colour guideline`}</ExternalLink>{` for more information.`}</li>
      <li parentName="ul">{`It is recommended (but not required) to offer a way to turn off less important notifications. Refer to `}<ExternalLink href="https://www.w3.org/WAI/WCAG21/Understanding/interruptions.html" mdxType="ExternalLink">{`WCAG 2.1 Interruptions guideline`}</ExternalLink>{` for more information.`}</li>
      <li parentName="ul">{`HDS Toast notifications use `}<inlineCode parentName="li">{`role="alert"`}</inlineCode>{` which means they are immediately announced to the screen reader as the node is inserted to the DOM. Inline notifications do not need this role since they are created during page load.`}</li>
      <li parentName="ul">{`When a dismissible Notification is closed, the focus should return to the element that caused the Notification to appear. This allows keyboard and screen reader users to continue in the context before the Notification was shown.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      